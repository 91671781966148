<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.section.initial : "อะไหล่ตั้งต้น"
page.section.optional : "อะไหล่เสริม"

lot_part.field.mode : "ปรับค่าจาก Model"

display.show_model_detail : "แสดงข้อมูลเปรียบเทียบอะไหล่กับ Model"
button.manage_parts : "แก้ไขอะไหล่"
part.field.has_serial_no : "Serial No"
</i18n>

<template>
	<div class="page-padding">
		<a-button
				v-if="canManageParts"
				class="btn-page-action"
				type="primary"
				icon="tool"
				style="margin-right:24px"
				@click="clickManageParts">
				{{$t('button.manage_parts')}}
		</a-button>
		<a-checkbox v-model="showDetail" >
			{{$t('display.show_model_detail')}}
		</a-checkbox>


		<div v-for="section in displayList" :key="section.key" style="margin-bottom:32px">
			<div class="page-section-title">
				{{section.title}}
			</div>
			<a-table
				:loading="loading"
				bordered class="mytable"
				:row-key="record => record.index"
				:data-source="section.value"
				:pagination="false"
				:row-class-name="formatRow"
				size="small">
				<a-table-column
					align="center"
					:width="30">
					<template slot-scope="text,record,index">
						{{index + 1}}.
					</template>
				</a-table-column>
				<a-table-column
					:sorter="tableSortSKU"
					:title="$t('part.field.sku')" data-index='part.sku'
					:width="150">
					<template slot-scope="text,record">
						<router-link :to="{ name: 'part/view',params: { id: record.part_id } }"
							target="_blank">
							{{text}}
						</router-link>
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('common.table.column.detail')" data-index="part.name"
					:sorter="tableSortName">
					<template slot-scope="text,record">
						<MyPopover overlay-class-name="mycard-popover"
							:destroy-tooltip-on-hide="true">
							<template slot="content">
								<PartLiteCard :part="record.part" size="small"  link-target="_blank" />
							</template>
							{{record.part.name}}
						</MyPopover>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.part_type')" data-index='part.part_type'
					:width="120"
					:sorter="tableSortPartType">
					<template slot-scope="text">
						{{$tenum('part_type',text)}}
					</template>
				</a-table-column>

				<a-table-column
					v-if="showDetail" :title="$t('lot_part.field.mode')"
					data-index='mode'
					:width="150"
					:sorter="tableSortLotMode"
					default-sort-order="ascend">
					<template slot-scope="text">
						{{$tenum('lot_part_mode',text)}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.has_serial_no')" data-index='part.has_serial_no'
					align="center"
					:width="75">
					<template slot-scope="hasSerialNo">
						<a-icon v-if="hasSerialNo" type="check" class="text-success"/>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('lot_part.field.total')"
					data-index="total"
					:sorter="tableSortTotal"
					:width="120"
					align="right">
					<template slot-scope="total">
						{{$tc_my('part.number.display',total)}}
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('lot_part.field.remark')"
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message" class="cursor-pointer"/>
						</MyTooltip>
					</template>
				</a-table-column>
			</a-table>
		</div>

	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import SortModelPartMixin from '@mixins/drone/SortModelPartMixin.vue'
import MyTooltip from "@components/common/MyTooltip.vue"
import MyPopover from "@components/common/MyPopover.vue"
import axios from "axios"
import Vue from "vue"
import { mapGetters } from 'vuex'
import {Table,Checkbox} from "ant-design-vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
export default {
	components : {
		PartLiteCard , MyTooltip, MyPopover ,
		"a-checkbox" : Checkbox,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	mixins : [PageMixin,SortModelPartMixin] ,
	props : {
		model : {
			type: Object ,
			default : () => []
		} ,
		lot : {
			type: Object ,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			showDetail : false,
			lotParts : []
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		canManageParts() {
			return this.$authorize('update','droneLot');
		} ,
		displayList(){
			return [
				{
					key : 'initial' ,
					title : this.$t('page.section.initial') ,
					value : this.initialLotParts
				} ,
				{
					key : 'optional' ,
					title : this.$t('page.section.optional') ,
					value : this.optionalLotParts.map((optionalLotPart, index) => {
						return {
							...optionalLotPart,
							index
						}
					})
				} ,
			]
		} ,
		initialLotParts() {
			return this.lotParts.filter((part) => part.type == 'initial' && (this.showDetail || part.mode !== 'excluded'))
		} ,
		optionalLotParts() {
			return this.lotParts.filter((part) => part.type == 'optional'&& (this.showDetail || part.mode !== 'excluded'))
		}
	} ,
	watch: {
		lot : {
			handler() {
				this.fetchData()
			} ,
			deep: true
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		formatRow(record,index) {
			if (!this.showDetail)
				return null;
			switch (record.mode) {
				case 'change' : return 'change'
				case 'custom' : return 'new'
				case 'excluded' : return 'inactive'
			}
			return null
		},
		clickManageParts() {
			this.$router.push({
				name:'drone_lot/parts_manage',params:{id : this.lot.id}
			})
		} ,
		fetchData() {
			if (!this.lot || !this.lot.id)
				return
			this.showPageLoading(true)
			this.loading = true
			axios.get("/api/drone-lots/"+this.lot.id+"/parts").then((response) => {
				this.lotParts = response.data.data.lotParts;
				for(const lotPart of this.lotParts) {
					Vue.set(lotPart,"part",this.getPartById(lotPart.partId))
				}
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
				this.loading = false
			})
		} ,
	}
}
</script>
